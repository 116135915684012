angular.module('eOpti.app.reports').config(['$stateProvider', function($stateProvider) {
    $stateProvider
        .state('app.reports', {
            abstract: true,
            url: '/reports',
            template: '<ui-view/>'
        })
        .state('app.reports.starloox', {
            url: '/star',
            template: '<opti-report-star-loox></opti-report-star-loox>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'star.breadcrumb.STARLOOX'
                }]
            }]
        })
        .state('app.reports.snapshots', {
            url: '/snapshots',
            template: '<opti-report-snapshots></opti-report-snapshots>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.snapshot.breadcrumb.SNAPSHOT'
                }];
            }]
        })
        .state('app.reports.task-sale', {
            url: '/task-sale',
            template: '<opti-report-tasks-sales></opti-report-tasks-sales>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.tasksale.breadcrumb.TASKSALE'
                }]
            }]
        })
        .state('app.reports.ranking', {
            url: '/ranking',
            template: '<opti-report-ranking></opti-report-ranking>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.ranking.breadcrumb.RANKING'
                }]
            }]
        })
        .state('app.reports.entries', {
            url: '/entries',
            template: '<opti-report-entries></opti-report-entries>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.entries.breadcrumb.ENTRIES'
                }]
            }]
        })
        .state('app.reports.budgets', {
            url: '/budgets',
            template: '<opti-report-budgets-departments></opti-report-budgets-departments>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.budget.BREADCRUMB'
                }]
            }]
        })
        .state('app.reports.smile_more_files', {
            url: '/smile_more_files',
            template: '<opti-smile-more-files></opti-smile-more-files>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.sm.FILES'
                }]
            }]
        })
        .state('app.reports.smile_more_order_validations', {
            url: '/smile_more_order_validations',
            template: '<opti-smile-more-validations></opti-smile-more-validations>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2,
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.sm.ORDERS'
                }]
            }]
        })
        .state('app.reports.forecast', {
            url: '/forecast',
            template: '<opti-report-forecast></opti-report-forecast>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.forecast.breadcrumb.FORECAST'
                }]
            }]
        })
        .state('app.reports.discounts', {
            url: '/discounts',
            template: '<opti-chart-discounts></opti-chart-discounts>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.discount.breadcrumb.DISCOUNT'
                }]
            }]
        })
        .state('app.reports.tasks', {
            url: '/tasks',
            template: '<opti-report-tasks></opti-report-tasks>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.tasks.breadcrumb.TASKS'
                }]
            }]
        })
        .state('app.reports.promotions', {
            url: '/promotions',
            template: '<opti-report-promotions></opti-report-promotions>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.promotion.breadcrumb.PROMOTIONS'
                }]
            }]
        })
        .state('app.reports.offers', {
            url: '/offers',
            template: '<opti-report-offers></opti-report-offers>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'sidebar.settings.reports.OFFERS'
                }]
            }]
        })

        // magazyn
        .state('app.reports.warehouse', {
            abstract: true,
            url: '/warehouse',
            template: '<ui-view/>'
        })
        .state('app.reports.warehouse.sales', {
            url: '/sales',
            template: '<opti-report-warehouse-sales></opti-report-warehouse-sales>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.warehouse.breadcrumb.SALES'
                }]
            }]
        })
        .state('app.reports.warehouse.tasks-turn-back', {
            url: '/tasks-turn-back',
            template: '<opti-report-warehouse-tasks-turn-back></opti-report-warehouse-tasks-turn-back>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.warehouse.breadcrumb.TASKSTURNBACK'
                }]
            }]
        })
        .state('app.reports.warehouse.manually-changes', {
            url: '/manually-changes',
            template: '<opti-report-warehouse-manually-changes></opti-report-warehouse-manually-changes>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.warehouse.breadcrumb.MANUALLYCHANGES'
                }]
            }]
        })
        .state('app.reports.warehouse.products', {
            url: '/products',
            template: '<opti-report-warehouse-products></opti-report-warehouse-products>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'report.warehouse.breadcrumb.PRODUCTS'
                }]
            }]
        })
        .state('app.reports.sunloox', {
            url: '/sunloox',
            template: '<opti-report-sun-loox></opti-report-sun-loox>',
            controller: ['breadcrumb', function(breadcrumb) {
                breadcrumb.data = [{
                    last: 2
                }, {
                    name: 'sidebar.settings.REPORTS'
                }, {
                    name: 'sidebar.settings.reports.SUNLOOX'
                }]
            }]
        })
}])
